var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{staticClass:"menucabeza",attrs:{"toggleable":"lg","variant":"light"}},[_c('b-navbar-brand',{attrs:{"href":"#","to":"/"}},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"dlogo"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.baseUrl()+'logos/logo.png'}})])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_vm._l((_vm.menuprincipal.menom),function(item,id){return _c('b-nav-item',{key:id,attrs:{"font-scale":"6","to":_vm.menuprincipal.menruta[id]}},[_vm._v(" "+_vm._s(item)+" ")])}),_c('b-nav-item',{attrs:{"font-scale":"6","href":"/blockinstitucional"}},[_vm._v("Block Institucional")]),_c('b-nav-item',{attrs:{"font-scale":"6","href":"https://login.microsoftonline.com/common/oauth2/authorize?client_id=00000002-0000-0ff1-ce00-000000000000&redirect_uri=https%3a%2f%2foutlook.office.com%2fowa%2f&resource=00000002-0000-0ff1-ce00-000000000000&response_mode=form_post&response_type=code+id_token&scope=openid&msafed=0&client-request-id=64be7ed2-0ce8-4fbc-a100-b7f0e3a939df&protectedtoken=true&domain_hint=gimnasioguatiquia.edu.co&nonce=637082168660821517.b621fb21-6bc1-48ec-a4fd-aa2e0d94e45b&state=FYvJDYAgEABBW7ANjkVYsJwFlsSEyMMY2xcfM_MaKYRYJ8tE2ikRcY82OcCE-DdA1BkdtOxAYS6gfOKiyLeqiBzbenj2Icv5bma8ZJ7rvKl31lwfXcYH&sso_reload=true","target":"__blank"}},[_vm._v("Correo Institucional")])],2),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-btn',{staticClass:"btn-presentacion",on:{"click":function($event){return _vm.nuevaruta(
              'https://www.sistemasaberes.com/ingreso-a-la-plataforma/'
            )}}},[_c('span',{staticClass:"icon-books",attrs:{"aria-hidden":"true"}}),_vm._v("SABERES APP")]),_c('b-btn',{staticClass:"y2",on:{"click":function($event){return _vm.nuevaruta(
              'https://normacolombia.ingeniat.com/login/tipo'
            )}}},[_c('span',{staticClass:"icon-calculator",attrs:{"aria-hidden":"true"}}),_vm._v("Plataforma EDUCA")]),_c('b-btn',{staticClass:"btn-presentacion",on:{"click":function($event){return _vm.nuevaruta(
              'https://api.whatsapp.com/send?phone=573192098659&app=facebook&entry_point=page_cta&fbclid=IwAR3_JrkhZ3_PrRMcdBkXuKZ4cWh1PKDE2LEnfVQj82pzyS0C6oRpdlU0rIU'
            )}}},[_c('span',{staticClass:"icon-whatsapp",attrs:{"aria-hidden":"true"}}),_vm._v(" Whatsapp")]),_c('b-btn',{staticClass:"btn-pagovirtual",on:{"click":function($event){return _vm.nuevaruta(
              'https://www.avalpaycenter.com/wps/portal/portal-de-pagos/web/banco-avvillas/resultado-busqueda/realizar-pago?idConv=00016382&origen=buscar'
            )}}},[_c('b-icon',{attrs:{"icon":"credit-card","font-scale":"1"}}),_vm._v(" Pago Virual")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }